import * as React from "react";
import { Container, Grid, } from "@mui/material";
import Header from "../components/header/Header";
import { useNavigate } from "react-router-dom";
import api from "../api";
import SearchBox from "./search/SearchBox";

const Site = (props) => {    
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = React.useState(false);

    React.useEffect(() => {
        checkLoggedIn();
    }, []);

    const checkLoggedIn = async () => {
        const ignoreAuth = props?.ignoreAuth; //show the page and ignore login status
        const isLoggedIn = await api.users.isAuthorized();
        setIsLoggedIn(isLoggedIn);

        if(!(isLoggedIn) && !ignoreAuth){
            navigate("/login");
        }
    }

    
    return (
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={12} md={2}>
                    <Header isLoggedIn={isLoggedIn}/>
                </Grid>
                <Grid item xs={12} md={10} sx={{marginBottom: "1rem"}}>
                    <SearchBox/>
                    {props.children}
                </Grid>
            </Grid>
        </Container>
    );
}

export default Site;
