import * as React from "react";
import { Paper, IconButton, Stack, Button, Chip, Avatar, Typography, Box } from "@mui/material";
import VerifiedIcon from '@mui/icons-material/Verified';
import EditIcon from '@mui/icons-material/Edit';
import { ThemeContext } from "../../theme/ThemeContext";
import { useParams } from "react-router-dom";
import api from "../../api";

const AccountCard = (props) => {
    const params = useParams();
    const {theme} = React.useContext(ThemeContext);
    const { account, setAccount } = React.useContext(props?.context);
    const [followText, setFollowText] = React.useState('');
    const [followsYouText, setFollowsYouText] = React.useState('');
    const [isLoggedIn, setIsLoggedIn] = React.useState();

    React.useEffect(() => {
        init();
    }, [account]);
    
    const init = async () => {
        if(isLoggedIn === undefined){
            setIsLoggedIn(await api.users.isAuthorized());
        }

        setFollowsYouText("Follows You");

        if (account?.isFollowed) {
            setFollowText('Unfollow');
        
            if (account?.isFollowingYou) {
                setFollowsYouText("Follows You Back");
            }
        } else {
            setFollowText('Follow');
        }
    }

    const follow = () => {
        let followers = account?.followers;

        if(account?.isFollowed){
            api.users.unfollow(params?.user);
            followers--;
        }
        else{
            api.users.follow(params?.user);
            followers++;
        }

        setAccount({...account, isFollowed: !account?.isFollowed, followers});
    }

    const hoverStyle = {
        position: 'absolute',
        right: '0',
    }

    const textStyle = {
        whiteSpace: 'nowrap'
    }

    const verifiedCheck = () => account?.verified && <VerifiedIcon color="primary"/>;

    return (
        <Paper variant="outlined" sx={{padding: "0.5rem"}}>
            <Stack direction='row' spacing={2} sx={{position: 'relative'}}>
                <Stack spacing={1}>
                    <Box>
                        <Avatar alt={account?.name} src={account?.pic} sx={{ backgroundColor: theme.palette.primary.light, width: 80, height: 80 }}/>
                    </Box>
                    {(isLoggedIn && !account?.editable) ? <Button onClick={follow} sx={{width: "80px", fontSize: "0.8rem"}}>{followText}</Button> : undefined}
                </Stack>

                <Stack spacing={0.5} sx={{width: '100%'}}>
                    <Stack direction='row' spacing={2}>
                        <Stack spacing={0.5}>
                            <Typography variant="h6" sx={textStyle}>{account?.name} {verifiedCheck()}</Typography>
                            <Typography variant="body1" sx={textStyle}>{account?.username}</Typography>
                        </Stack>
                        
                        <Stack direction='row' spacing={2} justifyContent="space-evenly" width="100%">
                            <Stack spacing={0.5}>
                                <Typography sx={textStyle}>Following: {account?.following || 0}</Typography>
                                <Typography sx={textStyle}>Followers: {account?.followers || 0}</Typography>
                            </Stack>

                            <Stack spacing={0.5}>
                                <Typography sx={textStyle}>Rated: {account?.movies?.Ratings || 0}</Typography>
                                <Typography sx={textStyle}>Watched: {account?.movies?.Watched || 0}</Typography>
                            </Stack>
                        </Stack>
                    </Stack>

                    <Typography sx={{fontStyle: 'italic'}}>{account?.bio}</Typography>
                </Stack>
                
                {(isLoggedIn && account?.editable) ? <IconButton color="secondary" aria-label="edit profile" sx={{top: '0', ...hoverStyle}}><EditIcon /></IconButton> : undefined}
                {(isLoggedIn && account?.isFollowingYou) ? <Chip label={followsYouText} size="small" color="secondary" variant="outlined" sx={{bottom: '0', ...hoverStyle}}/> : undefined}
            </Stack>
        </Paper>
    );
}

export default AccountCard;
