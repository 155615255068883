import { Box } from "@mui/material";

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
        <Box hidden={value !== index} {...other}>
            {value === index && (
                children
            )}
        </Box>
    );
}

export default TabPanel;