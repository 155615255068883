import request from "../request";
import storage from "../../utils/storage";

/**
 *  user: string("username")
 *  mid: string("imdbID")
 *  token: string("token")
 */
const addToWatchList = async (mid) => {
    const user = storage.getUsername();
    const token = storage.getLoginAuth();

    const response = await request.apiPOST('/movies/add-to-watch',{
        user,
        mid,
        token,
    });

    return response;
}

/**
 *  user: string("username")
 *  mid: string("imdbID")
 *  token: string("token")
 */
const addToWatchedList = async (mid) => {
    const user = storage.getUsername();
    const token = storage.getLoginAuth();

    const response = await request.apiPOST('/movies/add-to-watched',{
        user,
        mid,
        token,
    });

    return response;
}

/**
 *  user: string("username")
 *  mid: string("imdbID")
 *  token: string("token")
 *  rating: int(1-5)
 *  caption: string("test caption")
 */
const rateMovie = async (mid, rating, caption) => {
    const user = storage.getUsername();
    const token = storage.getLoginAuth();

    const response = await request.apiPOST('/movies/rate',{
        user,
        mid,
        token,
        rating,
        caption,
    });

    return response;
}

const user = { addToWatchList, addToWatchedList, rateMovie };

export default user;