import * as React from "react";
import { Stack, Divider, Avatar, IconButton, Typography, Dialog, Slide, Box } from "@mui/material";
import { Menu as MenuIcon, Close as CloseIcon, } from '@mui/icons-material/';
import { HomeOutlined as HomeIcon, Search as SearchIcon, NotificationsOutlined as NotifIcon, PersonOutlined as AccountIcon, SettingsOutlined as SettingsIcon, } from '@mui/icons-material/';
import { ThemeContext } from "../../theme/ThemeContext";
import Logo from '../../assets/logo/logo.png';
import { AccountContext } from "../../pages/account/AccountContext";
import RouterLink from "../RouterLink";

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="down" ref={ref} {...props} />;
});

const linkHover = {
    backgroundColor: '#e7e7e8',
    borderRadius: '2rem'
}
const linkStyle = {
    margin: '0.25rem',
    padding: '0.75rem',
    textAlign: 'start',
    '&:hover': linkHover,
};

const HeaderLink = (props) => {
    return (
        <RouterLink to={props?.to ?? '/'} sx={linkStyle}>
            <Stack direction='row' alignItems="center" spacing={1}>
                {props?.icon}
                <Typography>{props?.children}</Typography>
            </Stack>
        </RouterLink>
    )
}

const Header = (props) => {
    const {theme} = React.useContext(ThemeContext);
    const {account} = React.useContext(AccountContext);
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [isLoggedIn, setIsLoggedIn] = React.useState(props?.isLoggedIn);

    React.useEffect(() => {
        setIsLoggedIn(props?.isLoggedIn);
    }, [props?.isLoggedIn]);

    const headerStyle = {
        borderInlineEnd: `1px solid ${theme.palette.divider}`,
        boxSizing: 'border-box',
        // height: '100vh',
        position: 'sticky',
        top: 0,
        textAlign: 'center',
        transition: '0.5s',
        zIndex: 100
    };

    const divider = <Divider orientation="horizontal" flexItem />;

    const accountStyle = {
        marginTop: 'auto !important',
        '&:hover': linkHover,
    };

    const menuitems = (
        <>
            <Stack direction="column" divider={divider} sx={{width: '100%'}}>
                <HeaderLink to='/home' icon={<HomeIcon/>}>Home</HeaderLink>
                <HeaderLink to='/discover' icon={<SearchIcon/>}>Discover</HeaderLink>
                {isLoggedIn && <HeaderLink to='/notifications' icon={<NotifIcon/>}>Notifications</HeaderLink>}
                {isLoggedIn && <HeaderLink to='/account' icon={<AccountIcon/>}>Account</HeaderLink>}
                {isLoggedIn && <HeaderLink to='/settings' icon={<SettingsIcon/>}>Settings</HeaderLink>}
            </Stack>

            {isLoggedIn && (
                <RouterLink to='/account' sx={accountStyle}>
                    <Stack direction="row" spacing={2} alignItems='stretch' sx={{padding: '0.5rem'}}>
                        <Avatar alt={account?.name} src={account?.pic} sx={{ width: 45, height: 45 }}/>
                            <Stack alignItems='stretch'>
                                <Typography variant="body1">{account?.name}</Typography>
                                <Typography variant="body2">@{account?.username}</Typography>
                            </Stack> 
                    </Stack>
                </RouterLink>
            )}
        </>
    );

    const desktopHeader = (
        <Stack direction="column"  divider={divider} sx={{display: {xs: 'none', md: 'flex'}, height: '100vh', ...headerStyle}} alignItems='center'>
            <Box sx={{backgroundColor: '#70A2E6', padding: '0.5rem 0', width: '100%'}}>
                <RouterLink to='/'>
                    <img src={Logo} height="40px" alt="Logo"/>
                </RouterLink>
            </Box>
            {menuitems}
        </Stack>
    );

    const mobileHeader = (
        <Stack direction="row" spacing={2} sx={{display: {md: 'none'}, ...headerStyle, boxShadow: menuOpen ? 'unset' : theme.shadows[4]}} justifyContent="space-between">
            <Box sx={{backgroundColor: '#70A2E6'}}>
                <RouterLink to='/'>
                    <img src={Logo} height="40px" alt="Logo"/>
                </RouterLink>
            </Box>
            <IconButton sx={{color: theme.palette.text.primary}} aria-label="show menu" onClick={() => setMenuOpen(!menuOpen)}>
                {menuOpen ? <CloseIcon/> : <MenuIcon/>}
            </IconButton>
        </Stack>
    );

    const menuStyle = {
        backgroundColor: theme.palette.background.default,
        height: '100%',
        padding: '0.5rem',
        textAlign: 'center',
        transition: '0.5s',
    }

    const mobileMenu = (
        <Dialog fullScreen open={menuOpen} onClose={() => setMenuOpen(false)} sx={{display: {md: 'none'}}} TransitionComponent={Transition}>
            {mobileHeader}

            <Stack direction="column" divider={divider} sx={menuStyle} alignItems='center'>
                {menuitems}
            </Stack>
        </Dialog>
    );

    return (
        <>
            {desktopHeader}
            {mobileHeader}
            {mobileMenu}
        </>
    )
}

export default Header;
