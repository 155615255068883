import request from "../request";
import lists from "./lists";
import user from "./user";

const getMovie = async (mid) => {
    let response = {};
    
    if(mid){
        response = await request.apiGET(`/movies/${mid}`);
    }

    return response;
}

const getRandomMovies = async (limit) => {
    const response = await request.apiGET(`/movies/random/${limit}`);

    return response;
}

const movies = { getMovie, getRandomMovies, ...lists, ...user};

export default movies;