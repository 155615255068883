import * as React from "react";
import { Paper, Typography, Stack } from "@mui/material";
import api from "../../../api";
import RouterLink from "../../../components/RouterLink";

const MovieCardHor = (props) => {
    const [movie, setMovie] = React.useState(props?.movie);
    const [mid, setMid] = React.useState(movie?.id || 'tt8111088');
    const [color, setColor] = React.useState('');
    const title = movie?.fullTitle || `${movie?.title || ''} ${movie?.description || ''}`;

    React.useEffect(() => {
        getMovie();
    }, []);

    //should be shared
    const getMovie = async () => {
        let newMovie = movie;

        if(!newMovie){
            let mid = props?.mid || 'tt8111088';
            setMid(mid);

            newMovie = await api.movies.getMovie(mid);
        }

        //replace with 'nopicture' image if current image is unavailable/invalid
        if(!newMovie.image.includes('movelib')){
            newMovie.image = 'https://cdn.movelib.com/images/nopicture.jpg';
        }

        setMovie(newMovie);
        setColor(newMovie.color);
    };

    //modify to also work for dark mode
    //should be shared
    const getBGSafeColor = () => {
        return color.isLight ? "#969696" : color.hex;
    };

    const movieStyle = {
        boxShadow: `0px 0px 0px 0 ${getBGSafeColor()}aa`,
        transition: "0.1s, background-color 0.3s",
        '&:hover': {
            backgroundColor: `${getBGSafeColor()}aa`,
            boxShadow: `0px 3px 4px 0 ${getBGSafeColor()}aa`,
        },
        width: '100%'
    };

    const imageStyle = {
        backgroundColor: color.hex,
        height: "8.5rem",
        minWidth:"6rem",
        overflow: "hidden",
        width:"6rem",
    };

    return (
        <RouterLink to={`/movie/${mid}`}>
            <Paper variant="outlined" sx={movieStyle}>
                <Stack direction="row" spacing={1}>
                    <Stack justifyContent="center" sx={imageStyle}>
                        <img id={`${mid}-img`} src={movie?.image || 'https://cdn.movelib.com/images/nopicture.jpg'} width="100%" alt={title}/>
                    </Stack>

                    <Stack spacing={1} sx={{ padding: '0.5rem' }}>
                        <Typography variant="h6">{title || ''}</Typography>
                        <Typography variant="body2">{movie?.plot || ''}</Typography>
                    </Stack>
                </Stack>
            </Paper>
        </RouterLink>
    );
}

export default MovieCardHor;
