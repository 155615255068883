import * as React from "react";
import { createTheme } from "@mui/material/styles";
import storage from "../utils/storage";

//shared theme stuff
const theme = {
    palette: {
        primary: {
            light: "#a4d3ff",
            main: "#70a2e6",
            dark: "#3a74b4",
            contrastText: "#fff",
        },
        secondary: {
            light: "#ffb8f4",
            main: "#e587c1",
            dark: "#b15891",
            contrastText: "#000",
        },
    },
    typography: {
        fontFamily: [
            "Josefin Sans",
            "sans-serif",
        ].join(","),
    },
};
/*
    primary: #70A2E6
    secondary: #E687C2
*/
const LightTheme = createTheme({
    ...theme,
    palette: {
        ...theme.palette,
        background: {
            // default: '#ffffff',
            // paper: '#cdd9e8',
            // paper2: '#ffffff',
        },
    },
});

const DarkTheme = createTheme({
    ...theme,
    palette: {
        mode: "dark",
        ...theme.palette,
        divider: "#282828",
        background: {
            // default: '#181818',
            // paper: '#1D1D1D',
            // paper2: '#282828',
        },
    },
});

//get dark mode setting from local storage or default to dark mode if undefined
const darkMode = storage.getDarkMode();
const Theme = darkMode === "true" ? DarkTheme : LightTheme;

const ThemeContext = React.createContext({
    theme: Theme,
    setTheme: () => {},
});

export { ThemeContext, Theme, DarkTheme, LightTheme };
