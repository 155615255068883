import * as React from "react";
import { Paper, Typography, Stack} from "@mui/material";

const CommentCard = (props) => {
    const [json] = React.useState(props?.json);

    return (
        <Paper variant="outlined" width="100%">
            <Stack direction="row" spacing={1}>
                <img src={json?.image} width="150px" alt={json?.text} style={{ minWidth: "150px" }}/>

                <Stack spacing={1} sx={{ padding: '0.5rem' }}>
                    <Typography variant="h6">"{json?.text || ''}"</Typography>
                    <Typography variant="body2">{json?.title || ''}</Typography>
                </Stack>
            </Stack>
        </Paper>
    );
}

export default CommentCard;
