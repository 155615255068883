import * as React from "react";
import api from "../../api";
import { Divider, Switch } from "@mui/material";
import { List, ListItem, ListItemIcon, ListItemText, ListSubheader, ListItemButton } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import DeleteIcon from '@mui/icons-material/Delete';
import Site from "../Site";
import DarkModeToggle from "../../theme/DarkModeToggle";
import LogoutDialog from "./dialogs/LogoutDialog";
import DeleteAccountDialog from "./dialogs/DeleteAccountDialog";
import { AccountContext } from "../account/AccountContext";
import SnackContext from "../../components/SnackContext";

const Settings = () => {
    const {account, setAccount} = React.useContext(AccountContext);
    const [settings, setSettings] = React.useState(account?.settings);
    const [watchPublic, setWatchPublic] = React.useState(settings?.watchPublic);
    const [watchedPublic, setWatchedPublic] = React.useState(settings?.watchedPublic);
    const [logoutDialogOpen, setLogoutDialogOpen] = React.useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const { setSnackMessage } = React.useContext(SnackContext);

    React.useEffect(()=>{
        document.title = 'Settings | Mov e-Lib';

        getAccount();
    }, [])

    const getAccount = async () => {
        const account = await api.users.getAccount();
        const settings = account?.settings;

        setAccount(account);
        setSettings(settings);
        setWatchPublic(settings?.watchPublic);
        setWatchedPublic(settings?.watchedPublic);
    }

    const toggleWatchPublic = () => {
        setWatchPublic(!watchPublic);
    }

    const toggleWatchedPublic = () => {
        setWatchedPublic(!watchedPublic);
    }

    const openLogoutDialog = () => {
        setLogoutDialogOpen(true);
    }

    const openDeleteDialog = () => {
        setDeleteDialogOpen(true);
    }

    const saveSettings = async () => {
        const response = await api.users.editSettings(watchPublic, watchedPublic);
        
        setSnackMessage(response);
    }

    return (
        <Site>
            <List subheader={<ListSubheader>Settings</ListSubheader>}>
                <Divider component="li" />
                <ListItem disablePadding>
                    <ListItemButton onClick={toggleWatchPublic}>
                        <ListItemText primary="Watch List Public" />
                        <Switch edge="end" checked={watchPublic} onChange={toggleWatchPublic}/>
                    </ListItemButton>
                </ListItem>

                <Divider component="li" />
                <ListItem disablePadding>
                    <ListItemButton onClick={toggleWatchedPublic}>
                        <ListItemText primary="Watched List Public"/>
                        <Switch edge="end" checked={watchedPublic} onChange={toggleWatchedPublic}/>
                    </ListItemButton>
                </ListItem>

                <Divider component="li" />
                <ListItem dense={true} disablePadding>
                    <ListItemButton onClick={saveSettings}>
                        <ListItemText primary="Save Settings" primaryTypographyProps={{color: 'primary'}}/>
                    </ListItemButton>
                </ListItem>

                <Divider component="li" />
                <ListItem></ListItem>

                <Divider component="li" />
                <ListItem disablePadding>
                    <ListItemButton onClick={openLogoutDialog}>
                        <ListItemText primary="Dark Mode"/>
                    </ListItemButton>
                    <DarkModeToggle edge="end"/>
                </ListItem>
                
                <Divider component="li" />
                <ListItem disablePadding>
                    <ListItemButton onClick={openLogoutDialog}>
                        <ListItemIcon><LogoutIcon/></ListItemIcon>
                        <ListItemText primary="Logout"/>
                    </ListItemButton>
                    <LogoutDialog open={logoutDialogOpen} onClose={() => setLogoutDialogOpen(false)}/>
                </ListItem>

                <Divider component="li" />
                <ListItem disablePadding>
                    <ListItemButton onClick={openDeleteDialog}>
                        <ListItemIcon><DeleteIcon color="error"/></ListItemIcon>
                        <ListItemText primary="Delete Account" primaryTypographyProps={{color: "error"}}/>
                    </ListItemButton>
                    <DeleteAccountDialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}/>
                </ListItem>
            </List>
        </Site>
    );
};

export default Settings;
