import "./App.css";
import * as React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeContext, Theme } from "./theme/ThemeContext";
import { ThemeProvider } from '@mui/material/styles';
import ErrorBoundary from "./theme/ErrorBoundary";
import Feed from "./pages/feed/Feed";
import Discover from "./pages/search/Discover";
import Account from "./pages/account/Account.js";
import Login from "./pages/login/Login.js";
import Notifications from "./pages/notifications/Notifications";
import Settings from "./pages/settings/Settings";
import {Account as AccountObj, AccountContext} from "./pages/account/AccountContext";
import SDVSnackbar from "./components/SDVSnackbar";
import SnackContext from "./components/SnackContext";
import Movie from "./pages/movie/Movie";
import PageNotFound from "./pages/PageNotFound";

const App = () => {
    const [theme, setTheme] = React.useState(Theme);
    const [account, setAccount] = React.useState(AccountObj);
    const [snackMessage, setSnackMessage] = React.useState();
    const context = { theme, setTheme };

    return (
        <ThemeContext.Provider value={context}>
            <ThemeProvider theme={theme}>
                <ErrorBoundary>
                    <AccountContext.Provider value={{ account, setAccount }}>
                        <SnackContext.Provider value={{ snackMessage, setSnackMessage }}>
                            <BrowserRouter>
                                <Routes>
                                    <Route exact path="/" element={<Feed/>}/>
                                    <Route exact path="/home" element={<Feed/>}/>
                                    <Route exact path="/discover" element={<Discover/>}/>
                                    <Route exact path="/notifications" element={<Notifications/>}/>
                                    <Route exact path="/settings" element={<Settings/>}/>
                                    <Route exact path="/account" element={<Account/>}/>
                                    <Route exact path="/account/:user" element={<Account/>}/>
                                    <Route exact path="/login" element={<Login />} />
                                    <Route exact path="/movie/:mid" element={<Movie />} />
                                    <Route exact path="/*" element={<PageNotFound/>} />
                                </Routes>
                            </BrowserRouter>
                            <SDVSnackbar>{snackMessage}</SDVSnackbar>
                        </SnackContext.Provider>
                    </AccountContext.Provider>
                </ErrorBoundary>
            </ThemeProvider>
        </ThemeContext.Provider>
    );
};

export default App;
