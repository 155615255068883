import request from "../request";
import storage from "../../utils/storage";
import comments from "./comments";

/**
 *  user1: string("username") //of current user
 *  user2: string("username") //of post being viewed
 *  mid: string("tt*****")
 *  token: string("token")
 */
const like = async (user2, mid) => {
    const user1 = storage.getUsername();
    const token = storage.getLoginAuth();

    const response = await request.apiPOST('/rating/like', {
        user1,
        user2,
        mid,
        token
    });

    return response;
}

/**
 *  user1: string("username") //of current user
 *  user2: string("username") //of post being viewed
 *  mid: string("tt*****")
 *  token: string("token")
 */
const dislike = async (user2, mid) => {
    const user1 = storage.getUsername();
    const token = storage.getLoginAuth();

    const response = await request.apiPOST('/rating/dislike', {
        user1,
        user2,
        mid,
        token
    });

    return response;
}

const posts = { like, dislike, ...comments};

export default posts;