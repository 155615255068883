import * as React from "react";
import { Stack } from "@mui/material";
import MovieCardHor from "../../movie/cards/MovieCardHor";
import api from "../../../api";
import TabPanel from "./TabPanel";

const WatchTab = (props) => {
    const { account } = React.useContext(props?.context);
    const [movies, setMovies] = React.useState([]);

    React.useEffect(() => {
        init();
    }, [account])

    const init = async () => {
        if(account?.username){
            const movies = await api.users.getWatchList(account?.username);
            setMovies(movies?.data || []);
        }
    }

    const renderContents = () => {
        const list = [];
                
        if(movies){
            for(const mid of movies){
                list.push(<MovieCardHor key={mid} mid={mid}/>);
            }
        }

        return (
            <Stack spacing={1}>
                {list}
            </Stack>
        );
    }
    
    return (
        <TabPanel id="watch-tab" {...props}>
            {renderContents()}
        </TabPanel>
    );
}

export default WatchTab;
