import * as React from "react";
import { Stack } from "@mui/material";
import { LoginSocialGoogle, LoginSocialTwitter, LoginSocialApple } from 'reactjs-social-login';
import { GoogleLoginButton, TwitterLoginButton, AppleLoginButton } from 'react-social-login-buttons';

const REDIRECT_URI = window.location.href;

const SocialLogin = () => {    
    const [provider, setProvider] = React.useState('');
    const [profile, setProfile] = React.useState();
  
    const onLoginStart = () => {
      console.log('login start');
      console.log(provider);
      console.log(profile);
    };
  
    const onLoginSuccess = ({ provider, data }) => {
        console.log(provider);
        console.log(data);
        setProvider(provider);
        setProfile(data);
    };

        
    const onLogoutSuccess = () => {
        setProfile(null);
        setProvider('');
        console.log('logout success');
    };

    return (
        <Stack direction={{xs: "column", md: "row"}} spacing={{xs: 0, md: 1}} justifyContent="center">    
            <LoginSocialGoogle
                client_id={process.env.REACT_APP_GOOGLE_APP_ID || ''}
                onLoginStart={onLoginStart}
                onLogoutSuccess={onLogoutSuccess}
                redirect_uri={REDIRECT_URI}
                scope="openid profile email"
                discoveryDocs="claims_supported"
                access_type="offline"
                onResolve={onLoginSuccess}
                onReject={console.log}
            >
                <GoogleLoginButton />
            </LoginSocialGoogle>
            
            <LoginSocialTwitter
                client_id={process.env.REACT_APP_TWITTER_APP_ID || ''}
                redirect_uri={REDIRECT_URI}
                onLoginStart={onLoginStart}
                onLogoutSuccess={onLogoutSuccess}
                onResolve={onLoginSuccess}
                onReject={console.log}
            >
                <TwitterLoginButton />
            </LoginSocialTwitter>

            <LoginSocialApple
                client_id={process.env.REACT_APP_APPLE_APP_ID || ''}
                scope={'name email'}
                redirect_uri={REDIRECT_URI}
                onLoginStart={onLoginStart}
                onLogoutSuccess={onLogoutSuccess}
                onResolve={onLoginSuccess}
                onReject={console.log}
            >
                <AppleLoginButton />
            </LoginSocialApple>
        </Stack>
    );
}

export default SocialLogin;
