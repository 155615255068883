import * as React from "react";
import api from "../../api";
import MovieCardHor from "../movie/cards/MovieCardHor";
import { Stack, Typography } from "@mui/material";
import Site from "../Site";

const Feed = () => {    
    const [feed, setFeed] = React.useState();
    const [movies, setMovies] = React.useState();
    const [posts, setPosts] = React.useState();

    React.useEffect(() => {
        document.title = 'Feed | Mov e-Lib';
        getFeed();
    }, []);

    const getFeed = async () => {
        if(!feed){
            const feed = await api.users.getFeed();
            
            if(feed?.movies){
                setFeed(feed);
                setMovies(feed?.movies);
                setPosts(feed?.posts);
            }
            else{
                const movies = await api.movies.getRandomMovies(15);
                setMovies(movies?.data);
            }
        }
    }

    const renderPosts = () => {
        const list = [];
        
        if(posts){
            for(const post of posts){
                list.push(<MovieCardHor  key={`${post.user_id}/${post.mid}`} mid={post.mid}/>);
            }
        }

        return (
            <Stack spacing={1}>
                {list}
            </Stack>
        );
    }

    const renderMovies = () => {
        const list = [];
        
        if(movies){

            for(const movie of movies){
                list.push(<MovieCardHor key={movie.mid} mid={movie.mid}/>);
            }
        }

        return (
            <Stack spacing={1}>
                {list}
            </Stack>
        );
    }

    return (
        <Site ignoreAuth={true}>
            <Stack spacing={2} alignItems="center">
                {renderPosts()}
                <Typography variant="h5">Movie Suggestions</Typography>
                {renderMovies()}
            </Stack>
        </Site>
    );
}

export default Feed;
