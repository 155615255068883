import * as React from "react";
import { Stack } from "@mui/material";
import api from "../../../api";
import CommentCard from "../../../components/posts/CommentCard";
import TabPanel from "./TabPanel";
  
const CommentTab = (props) => {
    const { account } = React.useContext(props?.context);
    const [ comments, setComments ] = React.useState([]);

    React.useEffect(() => {
        init();
    }, [account])

    const init = async () => {
        if(account?.comments){
            const comments = [];

            for(const comment of account?.comments){
                const json = await getCommentItem(comment);

                comments.push(json);
            }

            setComments(comments);
        }
    }

    //TODO: make this into one api new call?
    const getCommentItem = async (comment) => {
        const userID = comment[0];
        const postID = comment[1];
        const text = comment[2];
    
        const username = (await api.users.getUsername(userID))?.username;
        const post = (await api.users.getPost(username, postID)).post;
        const movie = await api.movies.getMovie(postID);
    
        const commentJSON = {
            image: movie.image,
            text,
            title: movie.title,
            movie,
            post,
            username,
        };
    
        return commentJSON;
    }

    const renderContents = () => {
        const list = [];
                
        if(comments){
            for(const json of comments){
                list.push(<CommentCard key={`${json.username}/${json.movie.id}`} json={json}/>);
            }
        }

        return (
            <Stack spacing={1}>
                {list}
            </Stack>
        );
    }
    
    return (
        <TabPanel id="comment-tab" {...props}>
            {renderContents()}
        </TabPanel>
    );
}

export default CommentTab;
