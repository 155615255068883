import request from "../request";
import storage from "../../utils/storage";

/**
 * /rating/comments/:username/:mid
 */
const getComments = async (username, mid) => {
    const response = await request.apiGET(`/rating/comments/${username}/${mid}`);

    return response;
}

/**
 *  user: string("username") //of page being viewed
 *  token: string("token")
 *  loggedInUser: string("username") //of current user
 *  mid: string("imdbID")
 *  caption: string("test caption")
 */
const addComment = async (user, mid, caption) => {
    const loggedInUser = storage.getUsername();
    const token = storage.getLoginAuth();

    const response = await request.apiPOST('/rating/add-comment',{
        user,
        token,
        loggedInUser,
        mid,
        caption,
    });

    return response;
}

/**
 *  user: string("username") //of page being viewed
 *  token: string("token")
 *  loggedInUser: string("username") //of current user
 *  mid: string("imdbID")
 *  commid: int(1)
 */
const deleteComment = async (user, mid, commid) => {
    const loggedInUser = storage.getUsername();
    const token = storage.getLoginAuth();

    const response = await request.apiPOST('/rating/delete-comment',{
        user,
        token,
        loggedInUser,
        mid,
        commid,
    });

    return response;
}

const user = { getComments, addComment, deleteComment };

export default user;