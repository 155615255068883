import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import api from "../../../api";
import SnackContext from "../../../components/SnackContext";

const DeleteAccountDialog = (props) => {
    const [open, setOpen] = React.useState(props?.open);
    const navigate = useNavigate();
    const { setSnackMessage } = React.useContext(SnackContext);

    React.useEffect(() => {
        setOpen(props?.open);
    }, [props?.open]);

    const closeDialog = () => {
        setOpen(false);
        props?.onClose();
    };

    const confirmDelete = async () => {
        closeDialog();
        const response = await api.users.deleteAccount();
        setSnackMessage(response);

        if(response?.status === 200){
            await api.users.logout();
            navigate("/login");
        }
    }

    return (
        <Dialog open={open} onClose={closeDialog}>
            <DialogTitle>Confirm Account Deletion:</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete your account? You will be signed out and your account will be automatically deleted in 7 days. You can contact us within the next 7 days at dotsdv@gmail.com if you change your mind
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>Cancel</Button>
                <Button onClick={confirmDelete} variant="contained" autoFocus>Delete my account</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteAccountDialog;
