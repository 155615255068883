import request from "../request";
import storage from "../../utils/storage";

/**
 *  user: string("username") //of page being viewed
 *  token: string("token")
 *  loggedInUser: string("username") //of current user
 */
const getAccount = async (username) => {
    const loggedInUser = storage.getUsername() || '';
    const user = username || loggedInUser;
    const token = storage.getLoginAuth();
    
    if(!loggedInUser || !user || !token){
        return {};
    }

    const response = await request.apiPOST('/users/account', {
        user,
        token,
        loggedInUser
    });

    if(!username && response?.status === 200){
        storage.setMyAccount(response);
    }

    return response;
}

/**
 *  user: string("username") 
 *  token: string("token")
 *  timestamp: string("timestamp")
 */
const getFeed = async (timestamp = '') => {
    const user = storage.getUsername();
    const token = storage.getLoginAuth();

    if(!user || !token){
        return {};
    }

    const response = await request.apiPOST('/users/feed', {
        user,
        token,
        timestamp
    });

    return response;
}

/**
 *  user1: string("username") //of current user
 *  user2: string("username") //of page being viewed
 *  token: string("token")
 */
const follow = async (user2) => {
    const user1 = storage.getUsername();
    const token = storage.getLoginAuth();

    if(!user1 || !user2 || !token){
        return {};
    }

    const response = await request.apiPOST('/users/follow', {
        user1,
        user2,
        token,
    });

    return response;
}

/**
 *  user1: string("username") //of current user
 *  user2: string("username") //of page being viewed
 *  token: string("token")
 */
const unfollow = async (user2) => {
    const user1 = storage.getUsername();
    const token = storage.getLoginAuth();

    if(!user1 || !user2 || !token){
        return {};
    }

    const response = await request.apiPOST('/users/unfollow', {
        user1,
        user2,
        token,
    });

    return response;
}

/**
 *  user: string("username") 
 *  token: string("token")
 *  timestamp: string("timestamp")
 */
const getNotifications = async (timestamp = '') => {
    const user = storage.getUsername();
    const token = storage.getLoginAuth();

    if(!user || !token){
        return {};
    }

    const response = await request.apiPOST('/users/notifications', {
        user,
        token,
        timestamp
    });

    return response;
}

/**
 * endpoint: "/search/get-following/:username"
 */
const getFollowing = async (username, mid) => {
    if(!username || !mid){
        return {};
    }

    const response = await request.apiGET(`/users/get-following/${username}`);

    return response;
}

/**
 * endpoint: "/search/get-followers/:username"
 */
const getFollowers = async (username, mid) => {
    if(!username || !mid){
        return {};
    }

    const response = await request.apiGET(`/users/get-followers/${username}`);

    return response;
}

/**
 *  user: string("username") 
 *  token: string("token")
 */
const deleteAccount = async () => {
    const user = storage.getUsername() || '';
    const token = storage.getLoginAuth();
    
    if(!user || !token){
        return {};
    }

    const response = await request.apiPOST('/users/delete-account', {
        user,
        token,
    });

    return response;
}

const account = { getAccount, getFeed, follow, unfollow, getNotifications, getFollowing, getFollowers, deleteAccount};

export default account;