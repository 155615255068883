import * as React from "react";
// import { Skeleton, Stack, Tabs, Tab } from "@mui/material";
import { useParams } from "react-router-dom";
import api from "../../api";
import Site from "../Site";
import AccountCard from "./AccountCard";
import AccountTabs from "./AccountTabs";
import storage from "../../utils/storage";
import { AccountContext } from "./AccountContext";

const UserContext = React.createContext({ account: {}, setAccount: () => {} });

const Account = (props) => {
    const params = useParams();
    const [account, setAccount] = React.useState();
    const userContext = { account, setAccount };
    const [isLoggedIn, setIsLoggedIn] = React.useState();
    const {setAccount: setMyAccount} = React.useContext(AccountContext);

    React.useEffect(()=>{
        let user = '';

        if(params?.user){
            user = account ? `${account?.name} @(${account?.username})` : params?.user;
        }
        else{
            user = 'My Account';
            setAccount(storage.getMyAccount());
        }

        document.title = `${user} | Mov e-Lib`;
    }, [])

    //refresh account data when slug changes
    React.useEffect(() => {
        getAccount();
    }, [params?.user])

    React.useEffect(() => {
        init();
    }, [account]);
    
    const init = async () => {
        if(isLoggedIn === undefined){
            setIsLoggedIn(await api.users.isAuthorized());
        }

        await getAccount();
    }

    const getAccount = async () => {
        if(!account && !params?.user){
            //save user account
            const account = await api.users.getAccount();

            setMyAccount(account);
            setAccount(account);
        }
        else if(!account || ((params?.user || storage.getUsername()) !== account?.username)){
            const account = await api.users.getAccount(params?.user);

            setAccount(account);
        }

        return account;
    }

    return (
        <Site>
            <UserContext.Provider value={userContext}>
                <AccountCard context={UserContext}/>
                <AccountTabs context={UserContext}/>
            </UserContext.Provider>
        </Site>
    );
}

export default Account;
