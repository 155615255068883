import request from "../request";
import storage from "../../utils/storage";

/**
 *  user: string("username") 
 *  mid: string("imdbID")
 *  token: string("token")
 */
const checkWatchList = async (mid) => {
    return await checkList(mid, 'watch');
}

/**
 *  user: string("username") 
 *  mid: string("imdbID")
 *  token: string("token")
 */
const checkWatchedList = async (mid) => {
    return await checkList(mid, 'watched');
}

//helper
const checkList = async (mid, list) => {
    const user = storage.getUsername();
    const token = storage.getLoginAuth();

    const response = await request.apiPOST(`users/check-${list}-list`, {
        user,
        mid,
        token,
    });

    const isAdded = response.added;

    if (isAdded < 0) {
        return false;
    }
  
    return (isAdded === 1) ? true : false;
 }

/**
 *  user: string("username") 
 *  token: string("token")
 */
const getWatchList = async (user) => {
    user = user || storage.getUsername();
    const token = storage.getLoginAuth();

    const response = await request.apiPOST('users/get-watch-list', {
        user,
        token,
    });
 
    return response;
}

/**
 *  user: string("username") 
 *  token: string("token")
 */
const getWatchedList = async (user) => {
    user = user || storage.getUsername();
    const token = storage.getLoginAuth();

    const response = await request.apiPOST('users/get-watched-list', {
        user,
        token,
    });
 
    return response;
}

const lists = { checkWatchList, checkWatchedList, getWatchList, getWatchedList};

export default lists;