const axios = require('axios');

const apiURL = 'https://api.movelib.com';

const apiGET = async (service, params) => {
    let response = {};

    service = service[0] !== '/' ? `/${service}` : service;

    try{
        response = await axios.get(`${apiURL}${service}`, {
            params: params,
        });
    }
    catch(err){
        console.error(err);
    }

    return response.data;
}

const apiPOST = async (service, data) => {
    let response = {};

    service = service[0] !== '/' ? `/${service}` : service;

    try{
        response = await axios.post(`${apiURL}${service}`, data);
    }
    catch(err){
        console.error(err);
    }

    return response.data;
}

const request = { apiGET, apiPOST };

export default request;