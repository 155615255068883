import * as React from "react";
import { Stack, Typography } from "@mui/material";
// import api from "../api";
import Site from "../Site";
import MovieCardVert from "../movie/cards/MovieCardVert";
import MovieCardHor from "../movie/cards/MovieCardHor";
import api from "../../api";

const Discover = () => {
    const [topMovies, setTopMovies] = React.useState();
    const [newReleases, setNewReleases] = React.useState();
    const [comingSoon, setComingSoon] = React.useState();

    React.useEffect(() => {
        document.title = "Discover | Mov e-Lib";
        getLists();
    }, []);

    const getLists = async () => {
        if (!topMovies) {
            setTopMovies(await api.movies.getTopMovies());
        }
        if (!newReleases) {
            setNewReleases(await api.movies.getNewReleases());
        }
        if (!comingSoon) {
            setComingSoon(await api.movies.getComingSoon());
        }
    };

    const renderTopMovies = () => {
        const list = [];

        if (topMovies) {
            const arr = topMovies?.items.slice(0, 5);

            for (const movie of arr) {
                list.push(<MovieCardVert key={movie.id} movie={movie} />);
            }
        }

        return (
            <Stack direction="row" spacing={1}>
                {list}
            </Stack>
        );
    };

    const renderNewReleases = () => {
        const list = [];

        if (newReleases) {
            const arr = newReleases?.items.slice(0, 8);

            for (const movie of arr) {
                list.push(<MovieCardHor key={movie.id} movie={movie} />);
            }
        }

        return <Stack spacing={1}>{list}</Stack>;
    };

    const renderComingSoon = () => {
        const list = [];
        console.log(comingSoon?.items.length);
        if (comingSoon) {
            const arr = comingSoon?.items.slice(0, 8);

            for (const movie of arr) {
                list.push(<MovieCardHor key={movie.id} movie={movie} />);
            }
        }

        return <Stack spacing={1}>{list}</Stack>;
    };
    
    const discoverPage = (
        <Stack spacing={2} alignItems="center">
            {topMovies?.items?.length ? <Typography variant="h4">Top Movies</Typography> : ''}
            {renderTopMovies()}
            {newReleases?.items?.length ? <Typography variant="h4">New Releases</Typography> : ''}
            {renderNewReleases()}
            {comingSoon?.items?.length ? <Typography variant="h4">Coming Soon</Typography> : ''}
            {renderComingSoon()}
        </Stack>
    );

    return <Site ignoreAuth={true}> {discoverPage} </Site>;
};

export default Discover;
