const LOGIN_AUTH = 'loginAuth';
const LOGIN_EMAIL = 'loginEmail';
const LOGIN_USER = 'loginUser';
const DARK_MODE = 'dark-mode';
const MY_ACCOUNT = 'my-account';

//getters
const getLoginAuth = () => {
    return localStorage.getItem(LOGIN_AUTH);
}

const getEmail = () => {
    return localStorage.getItem(LOGIN_EMAIL);
}

const getUsername = () => {
    return localStorage.getItem(LOGIN_USER);
}

const getDarkMode = () => {
    return localStorage.getItem(DARK_MODE) ?? 'false';
}

const getMyAccount = () => {
    return JSON.parse(localStorage.getItem(MY_ACCOUNT));
}

//setters
const setLoginAuth = (value) => {
    return localStorage.setItem(LOGIN_AUTH, value);
}

const setEmail = (value) => {
    return localStorage.setItem(LOGIN_EMAIL, value);
}

const setUsername = (value) => {
    return localStorage.setItem(LOGIN_USER, value);
}

const setDarkMode = (enabled) => {
    localStorage.setItem(DARK_MODE, enabled);
}

const setMyAccount = (account) => {
    return localStorage.setItem(MY_ACCOUNT, JSON.stringify(account));
}

//do not clear certain values e.g. dark mode
const clear = () => {
    const darkMode = localStorage.getItem(DARK_MODE);
    
    localStorage.clear();

    localStorage.setItem(DARK_MODE, darkMode);
}

const storage = { getLoginAuth, getEmail, getUsername, getDarkMode, getMyAccount, setLoginAuth, setEmail, setUsername, setDarkMode, setMyAccount, clear };

export default storage;