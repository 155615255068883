import request from "../request";

const getTopMovies = async () => {
    const response = await request.apiGET('/movies/top-movies');

    return response;
}

const getNewReleases = async () => {
    const response = await request.apiGET('/movies/new-releases');

    return response;
}

const getComingSoon = async () => {
    const response = await request.apiGET('/movies/coming-soon');

    return response;
}

const lists = { getTopMovies, getNewReleases, getComingSoon };

export default lists;