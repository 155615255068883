import * as React from "react";
import { Typography, Stack } from "@mui/material";
import api from "../../../api";
import RouterLink from "../../../components/RouterLink";

const MovieCardVert = (props) => {
    const [movie, setMovie] = React.useState(props?.movie);
    const [mid, setMid] = React.useState(movie?.id || 'tt8111088');
    const [color, setColor] = React.useState('');
    const title = movie.fullTitle ?? `${movie.title} ${movie.description}`;

    React.useEffect(() => {
        getMovie();
    }, []);

    //should be shared
    const getMovie = async () => {
        let newMovie = movie;

        if(!newMovie){
            let mid = props?.mid || 'tt8111088';
            setMid(mid);

            newMovie = await api.movies.getMovie(mid);
        }

        //replace with 'nopicture' image if current image is unavailable/invalid
        if(!newMovie.image.includes('movelib')){
            newMovie.image = 'https://cdn.movelib.com/images/nopicture.jpg';
        }

        setMovie(newMovie);
        setColor(newMovie.color);
    };

    //modify to also work for dark mode
    //should be shared
    const getBGSafeColor = () => {
        return color.isLight ? "#969696" : color.hex;
    };

    const movieStyle = {
        aspectRatio: "12/17", // width/height
        backgroundColor: color.hex,
        border: `1px solid ${getBGSafeColor()}`,
        boxShadow: `0px 0px 0px 0 ${getBGSafeColor()}aa`,
        maxheight: "17rem",
        overflow: "hidden",
        position: "relative",
        transition: "0.2s",
        maxwidth: "12rem",
        '&:hover': {
            boxShadow: `2px 6px 2px 0 ${getBGSafeColor()}aa`,
            transform: "translateY(-6px)"
        }
    };
    
    const textStyle = {
        color: "white",
        backgroundColor: "var(--translucent)",
        fontSize: "0.875rem",
        padding: "0.5rem 0",
        position: "absolute",
        textAlign: 'center',
        width: "100%",
    };

    return (
        <RouterLink to={`/movie/${mid}`} sx={{flex: "1 1 0px"}}>
            <Stack justifyContent="center" sx={movieStyle}>
                <img id={`${movie?.id}-img`} src={movie?.image || 'https://cdn.movelib.com/images/nopicture.jpg'} width="100%" alt={`${title} poster`}/>

                <Typography sx={{ top: "0", ...textStyle }}>{movie.type}</Typography>
                <Typography sx={{ bottom: "0", ...textStyle }}>{title}</Typography>
            </Stack>
        </RouterLink>
    );
};

export default MovieCardVert;
