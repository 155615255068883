import * as React from "react";
import api from "../../api";
import { Paper, Stack, Typography, Button, TextField, Chip, IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import Site from "../Site";
import { useParams } from "react-router-dom";
import SnackContext from "../../components/SnackContext";
import Rating from "./Rating";
import storage from "../../utils/storage";

const Movie = () => {
    const { mid } = useParams();
    const [movie, setMovie] = React.useState();
    const [rating, setRating] = React.useState(0);
    const [review, setReview] = React.useState('');
    const [isRated, setIsRated] = React.useState(false);
    const [isInWatch, setIsInWatch] = React.useState(false);
    const [isInWatched, setIsInWatched] = React.useState(false);
    const [color, setColor] = React.useState('');
    const { setSnackMessage } = React.useContext(SnackContext);
    const title = movie?.fullTitle || `${movie?.title || ''} ${movie?.description || ''}`;

    React.useEffect(()=>{
        document.title = `${movie?.title || mid} | Mov e-Lib`;
    }, [title])

    React.useEffect(()=>{
        getMovie();
    }, [])

    //should be shared
    const getMovie = async () => {
        let newMovie = movie;

        if(!newMovie){
            newMovie = await api.movies.getMovie(mid || 'tt8111088');
        }

        //replace with 'nopicture' image if current image is unavailable/invalid
        if(!newMovie.image.includes('movelib')){
            newMovie.image = 'https://cdn.movelib.com/images/nopicture.jpg';
        }
        
        const post = await api.users.getPost(storage.getUsername(), mid);

        if(post?.caption){
            setReview(post.caption);
            setIsRated(true);
        }

        if(post?.rating){
            setRating(post.rating);
            setIsRated(true);
        }

        setIsInWatch(await api.users.checkWatchList(mid));
        setIsInWatched(await api.users.checkWatchedList(mid));

        setMovie(newMovie);
        setColor(newMovie.color.hex);
    };

    const getKeyWords = () => {
        const list = [];

        for(const keyword of movie?.keywordList || []){
            list.push(<Chip key={keyword} label={keyword} />);
        }

        return list;
    }

    const ratingChange = (e, newValue) => {
        if(newValue && newValue >= 1 && newValue <= 5){
            setRating(newValue);
        }
    }

    const reviewChange = (event) => {
        setReview(event.target.value);
    };

    const addRating = async (e, stars, caption) => {
        if(stars === undefined){
            stars = rating;
        }
        
        if(caption === undefined){
            caption = review;
        }

        // if(!caption){
        //     setSnackMessage("Please enter a caption");
        //     return;
        // }

        const result = await api.movies.rateMovie(mid, stars, caption)

        //result.added 1 is added, 2 is modified
        if (result.added > 0) {
            setIsRated(true);
        } else {
            setIsRated(false);
        }

        setSnackMessage(result);
    };

    const deleteRating = async () => {
        setRating(0);
        setReview('');
        await addRating(undefined, 0, '');
    };

    const addToWatchList = async () => {
        const result = await api.movies.addToWatchList(mid);

        if (result.added > 0) {
            setIsInWatch(true);
        } else {
            setIsInWatch(false);
        }
    
        //TODO: fix status
        setSnackMessage(result);
    }

    const addToWatchedList = async () => {
        const result = await api.movies.addToWatchedList(mid);

        if (result.added > 0) {
            setIsInWatched(true);
        } else {
            setIsInWatched(false);
        }
    
        setSnackMessage(result);
    }

    const movieStyle = {
        backgroundColor: color,
        height: "20rem",
        minWidth:"15rem",
        overflow: "hidden",
        position: "relative",
        width:"15rem",
    };

    const textStyle = {
        color: "white",
        backgroundColor: "var(--translucent)",
        padding: "0.5rem 0",
        position: "absolute",
        textAlign: 'center',
        width: "100%",
    };

    return (
        <Site ignoreAuth={true}>
            <Stack spacing={1}>
                <Paper variant="outlined" width="100%">
                    <Stack direction="row" spacing={1}>
                        <Stack justifyContent="center" sx={movieStyle}>
                            <img id={`${movie?.id}-img`} src={movie?.image || 'https://cdn.movelib.com/images/nopicture.jpg'} width="100%" alt={`${title} poster`}/>

                            <Typography sx={{ top: "0", ...textStyle }}>{movie?.type || 'Movie'}</Typography>
                            <Typography sx={{ bottom: "0", ...textStyle }}>Overall Rating: TBA</Typography>
                        </Stack>

                        <Stack spacing={1} sx={{ padding: '0.5rem', width: '100%'}}>
                            <Typography variant="h6">{title || ''}</Typography>
                            <Typography variant="body2">{movie?.plot || ''}</Typography>
                            <Stack direction="row" alignItems="center" justifyContent="center">
                                <Rating name="simple-controlled" value={rating} size="large" onChange={ratingChange}/>
                                {isRated ? (
                                    <IconButton variant="contained" onClick={deleteRating} color="secondary" aria-label="delete rating">
                                        <DeleteIcon />
                                    </IconButton>)
                                    : ""}
                            </Stack>
                            <TextField label="Type your review..." multiline rows={2} variant="filled" value={review} onChange={reviewChange}/>
                            <Button variant="contained" onClick={addRating}>{isRated ? 'Modify Review' : 'Add Rating'}</Button>

                            <Stack spacing={1} direction="row" sx={{width: "100%"}}>
                                <Button variant="outlined" sx={{width: "100%"}} onClick={addToWatchList}>{isInWatch ? 'Remove From Watchlist': 'Add To WatchList'}</Button>
                                <Button variant="outlined" sx={{width: "100%"}} onClick={addToWatchedList}>{isInWatched ? 'Unmark As Watched' : 'Mark As Watched'}</Button>
                            </Stack>
                        </Stack>
                    </Stack>
                </Paper>

                <Typography variant="body2">Tags:</Typography>
                <Stack spacing={1} direction="row" sx={{width: "100%", flexWrap: 'wrap', gap: '0.5rem'}} justifyContent="center">{getKeyWords()}</Stack>
            </Stack>
        </Site>
    );
};

export default Movie;
