import * as React from "react";
import { Tabs, Tab } from "@mui/material";
import StarIcon from '@mui/icons-material/Star';
import ChatIcon from '@mui/icons-material/Chat';
import HeartIcon from '@mui/icons-material/Favorite';
import TVIcon from '@mui/icons-material/Tv';
import RatingTab from "./tabs/RatingTab";
import WatchedTab from "./tabs/WatchedTab";
import WatchTab from "./tabs/WatchTab";
import CommentTab from "./tabs/CommentTab";
  
const AccountTabs = (props) => {
    const { account } = React.useContext(props?.context);
    const [tab, setTab] = React.useState(0);

    const tabChange = (event, newValue) => {
        setTab(newValue);
    };
    
    return (
        <>
            <Tabs value={tab} onChange={tabChange} variant="fullWidth">
                <Tab icon={<StarIcon />} iconPosition="start" label="Ratings" />
                {account?.editable && <Tab icon={<ChatIcon />} iconPosition="start" label="Comments" />}
                {(account?.editable || account?.settings?.watchPublic) && <Tab icon={<HeartIcon />} iconPosition="start" label="Watch List" />}
                {(account?.editable || account?.settings?.watchedPublic) && <Tab icon={<TVIcon />} iconPosition="start" label="Watched" />}
            </Tabs>

            <RatingTab context={props?.context} value={tab} index={0}/>
            <CommentTab context={props?.context} value={tab} index={1}/>
            <WatchTab context={props?.context} value={tab} index={2}/>
            <WatchedTab context={props?.context} value={tab} index={3}/>
        </>
    );
}

export default AccountTabs;
