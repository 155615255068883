import request from "../request";

/**
 * Searches for accounts (GET)
 * endpoint: "/search/users/:term/:page?"
 */
const searchUsers = async (term, page) => {
    const response = await request.apiGET(`/search/users/${term}/${page}`);

    return response;
}

const users = { searchUsers };

export default users;