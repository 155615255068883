import * as React from "react";
import { Stack } from "@mui/material";
import TabPanel from "./TabPanel";
import MovieCardHor from "../../movie/cards/MovieCardHor";
  
const RatingTab = (props) => {
    const { account } = React.useContext(props?.context);

    const renderContents = () => {
        const list = [];
        
        const movies = account?.posts || [];

        if(movies){

            for(const mid of movies){
                list.push(<MovieCardHor key={mid} mid={mid}/>);
            }
        }

        return (
            <Stack spacing={1}>
                {list}
            </Stack>
        );
    }
    
    return (
        <TabPanel id="ratings-tab" {...props}>
            {renderContents()}
        </TabPanel>
    );
}

export default RatingTab;
