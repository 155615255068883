import { Rating as MuiRating} from "@mui/material";
import { styled } from "@mui/material/styles";

//make it's own component
const Rating = styled(MuiRating)(({ theme }) => ({
    "& .MuiRating-iconFilled": {
        color: theme.palette.secondary.main,
    },
    "& .MuiRating-iconHover": {
        color: `${theme.palette.secondary.main}aa`,
    },
}));

export default Rating;