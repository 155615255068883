import request from "../request";
import storage from "../../utils/storage";

/**
 *  first: string("firstname")
 *  last: string("lastname")
 *  user: string("username")
 *  pass: string("plain_pass")
 *  email: string("email")
 *  dob: string("yyyy-mm-dd")
 *  gender: char("M", "F", "X")
 */
const signup = async ({first, last, user, pass, email, dob, gender}) => {
    const response = await request.apiPOST('users/signup', {
        first,
        last,
        user,
        pass,
        email,
        dob,
        gender,
    });

    console.log(response?.message);
 
    return response;
}

/**
 *  user: string("username")
 *  pass: string("plain_pass")
 *  remember: string("true", "permanent", "false")
 *  notifToken: string("fcm-client-token")
 */
const login = async (user, pass, remember) => {
    const response = await request.apiPOST('users/login', {
        user,
        pass,
        remember,
    });

    if(response?.status === 200){
        storage.setLoginAuth(response?.loginAuth);
        storage.setEmail(response?.loginEmail);
        storage.setUsername(response?.loginUser);
    }

    console.log(response?.message);
 
    return response;
}

/**
 *  user: string("username") 
 *  token: string("token")
 *  notifToken: string("fcm-client-token")
 */
const logout = async () => {
    const user = storage.getUsername();
    const token = storage.getLoginAuth();

    const response = await request.apiPOST('users/logout', {
        user,
        token,
    });

    if(response?.status === 200){
        storage.clear();
    }

    console.log(response?.message);
 
    return response;
}

/**
 *  user?: string("username")
 *  email?: string("email")
 *  token: string("token")
 *  --only one out of email and username is required
 */
const isAuthorized = async () => {
    const user = storage.getUsername();
    const token = storage.getLoginAuth();

    if(!user || !token){
        return false;
    }

    const response = await request.apiPOST('/users/is-authorized', {
        user,
        token,
    });

    return response?.isAuthorized;
}

/**
 *  user: string("username") 
 */
const pwReset = async (user) => {
    const response = await request.apiPOST('users/password-reset', {
        user,
    });

    console.log(response?.message);
 
    return response;
}


const auth = { signup, login, logout, isAuthorized, pwReset };

export default auth;