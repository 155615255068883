import auth from "./auth";
import account from "./account";
import lists from "./lists";
import edit from "./edit";
import request from "../request";

/**
 * endpoint: "/users/username/:userid"
 */
const getUsername = async (userid) => {
    if(!userid){
        return {};
    }

    const response = await request.apiGET(`/users/username/${userid}`);

    return response;
}

/**
 * endpoint: "/users/userid/:username"
 */
const getUserID = async (username) => {
    if(!username){
        return {};
    }

    const response = await request.apiGET(`/users/userid/${username}`);

    return response;
}

/**
 * endpoint: "/users/post/:username/:mid"
 */
const getPost = async (username, mid) => {
    if(!username || !mid){
        return {};
    }

    const response = await request.apiGET(`/users/post/${username}/${mid}`);

    return response.post;
}


const users = { getUsername, getUserID, getPost, ...auth, ...account, ...lists, ...edit};

export default users;