import * as React from "react";
import api from "../../api";
import { Stack, Paper } from "@mui/material";
import Site from "../Site";

const Notifications = () => {    
    const [notifs, setNotifs] = React.useState();

    React.useEffect(()=>{
        document.title = 'Notifications | Mov e-Lib';
    }, [])

    React.useEffect(() => {
        init();
    }, [notifs]);

    const init = async () => {
        if(!notifs){
            const notifs = await api.users.getNotifications();
            setNotifs(notifs?.data);
        }
    }

    const renderNotifs = () => {
        const list = [];
        
        if(notifs){
            for(const notif of notifs){
                list.push(<Paper key={notif.id} variant="outlined">{notif.message}</Paper>);
            }
        }

        return (
            <Stack spacing={1}>
                {list}
            </Stack>
        );
    }

    return (
        <Site>
            {renderNotifs()}
        </Site>
    );
}

export default Notifications;
