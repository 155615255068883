import request from "../request";

/**
 * Searches movie using omdb api (GET)
 * endpoint: "/search/movies/:term/:page"
 */
const searchMovie = async (term, page) => {
    const response = await request.apiGET(`/search/movies/${term}/${page}`);

    return response;
}

/**
 * Searches movie using imdb api advanced search (GET)
 * endpoint: "/search/movies-advanced/:term/:parameters"
 * 
 * format of params `key=value&key=value`
 * search term is included separately
 */
const advancedMovieSearch = async (term, filters) => {
    const response = await request.apiGET(`/search/movies-advanced/${term}/${filters}`);

    return response;
}

const movies = { searchMovie, advancedMovieSearch };

export default movies;