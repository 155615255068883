import * as React from "react";
import { TextField, Button, Container, Stack, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import SocialLogin from "./SocialLogin";
import Logo from '../../assets/logo/logo.png';
import SnackContext from "../../components/SnackContext";
import { AccountContext } from "../account/AccountContext";

const Login = () => {    
    const navigate = useNavigate();
    const { setAccount } = React.useContext(AccountContext);
    const [username, setUsername] = React.useState();
    const [password, setPassword] = React.useState();
    const { setSnackMessage } = React.useContext(SnackContext);

    const userChange = (e) => setUsername(e.target.value);
    const passChange = (e) => setPassword(e.target.value);

    React.useEffect(() => {
        document.title = 'Login | Mov e-Lib';
        checkLoggedIn();
    }, []);

    const checkLoggedIn = async () => {
        if(await api.users.isAuthorized()){
            // navigate("/");
        }
    }

    const login = async (e) => {
        e.preventDefault();

        if(!username || !password){
            setSnackMessage("Please fill in all the fields: ");

            return;
        }

        const response = await api.users.login(username, password, 'true');
        setSnackMessage(response);

        if(response?.status === 200){
            const account = await api.users.getAccount();
            setAccount(account);
            navigate("/");
        }
    }
  
    const logoStyle = {
        backgroundColor: '#70A2E6',
        padding: { xs: '15%', md: '5% 15%'},
        textAlign: 'center'
    };

    return (
        <Container>
            <Stack spacing={2}>
                <Box sx={logoStyle}>
                    <img src={Logo} width="100%" alt="Logo"/>
                </Box>
                <form onSubmit={login}>
                    <Stack spacing={2}>
                        <TextField label="Username" value={username} onChange={userChange} type="username"/>
                        <TextField label="Password" value={password} onChange={passChange} type="password"/>
                        {"Remember me"}
                        <Stack direction="row" spacing={2} justifyContent="center">    
                            <Button variant="contained" type="submit">Login</Button>
                            <Button>Sign up</Button>
                        </Stack>
                    </Stack>
                </form>

                <SocialLogin/>
            </Stack>
        </Container>
    );
}

export default Login;
