import * as React from "react";
import { TextField, InputAdornment, Box } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

const SearchBox = () => {
    // const [topMovies, setTopMovies] = React.useState();

    const search = (e) => {
        e.preventDefault();

        // navigate("/");
    }
    
    const style = {
        padding: '1rem 0'
    }

    return (
        <form onSubmit={search}>
            <Box sx={style}>
                <TextField placeholder="Search..." variant="standard" fullWidth type="text" InputProps={{
                    endAdornment: (<InputAdornment position="end">
                        <SearchIcon />
                    </InputAdornment>)
                }}/>
            </Box>
        </form>
    );
};

export default SearchBox;
