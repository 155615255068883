import request from "../request";
import storage from "../../utils/storage";

/**
 *  user: string("username") 
 *  token: string("token")
 *  watchpublic: bool(false)
 *  watchedpublic: bool(false)
 */
const editSettings = async (watchpublic, watchedpublic) => {
    const user = storage.getUsername();
    const token = storage.getLoginAuth();

    const response = await request.apiPOST('users/edit-settings', {
        user,
        token,
        watchpublic,
        watchedpublic
    });
 
    return response;
}

/**
 *  user: string("username") 
 *  token: string("token")
 *  new_user: string("token") //if being changed
 *  pic: string("imageB64") //base64 profile pic
 *  display_name: string("display_name")
 *  bio: string("bio")
 *  notifToken: string("fcm-client-token")
 */
const editProfile = async ({new_user, pic, display_name, bio}) => {
    const user = storage.getUsername();
    const token = storage.getLoginAuth();

    const response = await request.apiPOST('users/edit-settings', {
        user,
        token,
        new_user,
        pic,
        display_name,
        bio,
    });
 
    return response;
}

const edit = { editSettings, editProfile };

export default edit;