import * as React from "react";
import Site from "./Site";

const PageNotFound = (props) => {    

    React.useEffect(() => {
        document.title = 'Page Not Found | Mov e-Lib';
    }, []);

    return (
        <Site ignoreAuth={true}>
            <h2>404: Page Not Found</h2>
        </Site>
    );
}

export default PageNotFound;
